<template>
	<div>
		<v-sheet class="dense-inputs">
			<v-row no-gutters>
                <v-col class="col-lg-9 col-xs-12 ma-0 pa-0 d-flex flex-row">
                    <div class="d-flex align-center pt-3">
                        <v-text-field
                            :placeholder="$t('message.poNo')"
                            :value="searchValues.title"
                            autocomplete="new-password"
                            class="filter-width-200 force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            append-icon="search"
                            solo
                            @change="searchValues.title = $event"
                            @click:append="searchSdas('title')"
                            @click:clear="resetSearch()"
                            @keyup.enter.prevent="searchSdas('title')"
                        />
                        <v-autocomplete
                            :items="filterItems.buyer"
                            :loading="loading.filterItems.buyer"
                            :placeholder="$t('message.buyer')"
                            class="filter-width-200 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="Customer.title"
                            item-value="Customer.id"
                            solo
                            v-model="filterValues.buyer"
                            @change="filterSdas()"
                        />
                        <v-autocomplete
                            :items="filterItems.supplier"
                            :loading="loading.filterItems.supplier"
                            :placeholder="$t('message.spl')"
                            class="filter-width-200 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="Supplier.title"
                            item-value="Supplier.id"
                            solo
                            v-model="filterValues.supplier"
                            @change="filterSdas()"
                        />
                        <v-autocomplete
                            :items="filterItems.pod"
                            :loading="loading.filterItems.pod"
                            :placeholder="$t('message.pod')"
                            class="filter-width-200 ml-2"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="ShippingPort.title"
                            item-value="ShippingPort.id"
                            solo
                            v-model="filterValues.pod"
                            @change="filterSdas()"
                        />
                        <v-btn
                            :loading="loading.clear"
                            class="ml-2 px-2"
                            @click="clearFilters"
                        >{{ $t('message.clear') }}</v-btn>
                    </div>
                    <v-spacer/>
                    <ExportTableJsonWithAdd
                        :export-conditions="exportConditions"
                        :export-data="Sdas"
                        :export-fields="headers"
                        :export-source="'sdas'"
                        :has-add-function="true"
                        class="mt-3"
                        @add-action="newShippingInstruction"
                    />
                    <HelpPopUpV2 help-page-component="ShippingInstructionsListing"/>
                </v-col>
			</v-row>
		</v-sheet>
		<v-overlay
			:value="loading.sdas"
			absolute
			opacity="0.15"
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					/>
				</v-col>
			</v-row>
		</v-overlay>
		<v-data-table
			:footer-props="{
				itemsPerPageOptions: itemsPerPageOptions,
				showFirstLastPage: true
			}"
			:headers="headers"
            :hide-default-footer="hideDefaultFooter"
			:items="Sdas"
			:options="tableOptions"
			:server-items-length="totalSdas"
			calculate-widths
			class="mt-3 appic-table-light specification-table col-lg-9 col-xs-12 pa-0"
			dense
			id="shippingInstructionsTable"
			item-key="Sda.id"
            @update:options="updateDataTable"
		>
            <template v-slot:item.Sda.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.Sda.title }}</span>
            </template>
			<template v-slot:item.Sda.sdadate="{ item }">
				{{ item.Sda.sdadate != '0000-00-00' ? formatDate(item.Sda.sdadate) : '' }}
			</template>
			<template v-slot:item.Sda.sdasent_date="{ item }">
				{{ item.Sda.sdasent_date != '0000-00-00' ? formatDate(item.Sda.sdasent_date) : '' }}
			</template>
            <template v-slot:item.Sda.Customer.otsname="{ item }">
                {{ item.Sda.Customer.otsname ? item.Sda.Customer.otsname : item.Sda.Customer.title }}
            </template>
            <template v-slot:item.Sda.Supplier.otsname="{ item }">
				{{ item.Sda.Supplier.otsname }}
			</template>
            <template v-slot:item.Sda.c.title="{ item }">
                {{ item.Sda.Destinationport.title }}
            </template>
			<template v-slot:item.Sda.id="{ item }">
				<v-menu>
					<template v-slot:activator="{ on: menu }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
									<v-icon>more_vert</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('message.moreActions') }}</span>
						</v-tooltip>
					</template>
					<v-list dense>
						<v-list-item class="font-sm" @click="updateSda(item.Sda.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateSda') }}</v-list-item>
						<v-list-item class="font-sm" @click="cancelSda(item.Sda.id, item.Sda.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelSda') }}</v-list-item>
<!--						<v-list-item class="font-sm" @click="viewPdf(item.Sda.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>-->
					    <PrintShippingInstructionButton
                            :shipping-instruction-id="item.Sda.id"
                            :shipping-instruction-number="item.Sda.title"
                            :update-mode="true"
                            :list-mode="true"
                        />
                    </v-list>
				</v-menu>
			</template>
		</v-data-table>
		<SimpleAlert
			:alert_message.sync="dialogs.error_message"
			:dialog.sync="dialogs.error"
			@dialog-closed="dialogClosed"
		></SimpleAlert>
	</div>
</template>

<script>
	import {formatDate} from "Helpers/helpers";
	// import SimpleAlert from "./SimpleAlert";
	import {numberFormat} from "../../helpers/helpers";
	import {mapGetters, mapActions} from "vuex";
	import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';
    import {api} from "Api";

    const ExportTableJsonWithAdd = () => import("./ExportTableJsonWithAdd");
    const SimpleAlert = () => import("./SimpleAlert");
    const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");
    const PrintShippingInstructionButton = () => import("./Printing/PrintShippingInstructionButton.vue");

	export default {
		name: "ShippingInstructionsListingNew",
		components: {PrintShippingInstructionButton, ExportTableJsonWithAdd, HelpPopUpV2, SimpleAlert},
		data() {
			return {
                currentFilter: null,
                currentSearch: null,
				dialogs: {
					error:  false,
					error_message: ""
				},
                exportConditions: {},
				filterOptions: {
					status: {
						Status: {
							id: 1,
							title: 'open'
						}
					}
				},
                filterItems: {
                    buyer: [],
                    pod: [],
                    supplier: []
                },
                filterValues: {
                    buyer: null,
                    pod: null,
                    supplier: null
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
				loading: {
					filter: {
						buyers: false
					},
                    filterItems: {
                        buyer: null,
                        pod: false,
                        supplier: null
                    },
					search: false,
					sdas: false
				},
				searchField: 'Sda.title',
				searchTerm: null,
                searchValues: {
                    title: null
                },
				tableOptions: {},
			}
		},
		computed: {
			...mapMultiRowFields('sda',{
				Sdas: 'current.Sdas'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
			...mapFields('sda',{
				totalSdas: 'current.totalSdas'
			}),
			...mapGetters([
				'currencies'
			]),
			defaultItemsPerPage() {
				if(window.screen.height >= 800) return 15
				return 10
			},
			formatDate: () => formatDate,
			headers () {
				return [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Sda.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false
                    },
				    {
						id: 1,
						text: this.$t('message.poNumber'),
						value: 'Sda.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
					{
						id: 2,
						text: this.$t('message.siDate'),
						value: 'Sda.sdadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
                    {
                        id: 3,
                        text: this.$t('message.siSent'),
                        value: 'Sda.sdasent_date',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 4,
                        text: this.$t('message.buyer'),
                        value: 'Sda.Customer.otsname',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 5,
                        text: this.$t('message.supplier'),
                        value: 'Sda.Supplier.otsname',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 6,
                        text: this.$t('message.pod'),
                        value: 'Sda.Destinationport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    }
				]
			},
			itemsPerPageOptions() {
				let options = [10,20,50,-1];
				if(window.screen.height >= 800){
					options = [15,30,50,-1];
				}
				return options;
			},
			searchFields() {
				return this.headers.filter((header)=>header.searchable === true)
			},
		},
		methods: {
			...mapActions('sda',{
				cancelSdaById: 'cancelSdaById',
				getAllSdas: 'getAllSdas',
				searchAllSdas: 'searchAllSdas'
			}),
			async cancelSda (val, title) {
				if(await this.$root.$confirm(this.$t('message.cancelSda') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueSdaCancelAction'), {color: 'orange'})){
					this.cancelSdaById(val)
						.then((response) => {
							if(response.status == 'success'){
								this.$toast.success(this.$t('message.successes.sdaDeleted'),
									{
										classes: ['icon-float-left'],
										icon: 'check_circle_outline'
									}
								)
								this.loadSdas()
							} else {
								this.$toast.error(this.$t('message.errors.sdaNotDeleted'),
									{
										classes: ['icon-float-left'],
										icon: 'error_outline'
									}
								)
							}
						})
						.catch(()=>{
							this.$toast.error(this.$t('message.errors.sdaNotDeleted'),
								{
									classes: ['icon-float-left'],
									icon: 'error_outline'
								}
							)
						})
				}
			},
            clearFilters() {
                this.loading.clear = true
                this.filterValues.buyer = null
                this.filterValues.pod = null
                this.filterValues.supplier = null
                this.searching = false
                this.searchValues.title = null
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'Sda.sdadate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterSdas()
            },
			dialogClosed () {
				this.dialogs.error = false;
				this.dialogs.error_message = ''
			},
            filterSdas() {
                this.searchValues.title = null
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if(this.filterValues.buyer != null) conditions.push({field: 'Sda.Contract.customer_id', value: this.filterValues.buyer})
                    if(this.filterValues.pod != null) conditions.push({field: 'Sda.Contract.shippingport_id', value: this.filterValues.pod})
                    if(this.filterValues.supplier != null) conditions.push({field: 'Sda.Contract.supplier_id', value: this.filterValues.supplier})

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                    if(_.isEqual(this.currentFilter,this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }

                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['Sda.sdadate']
                    }
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['Sda.sdadate']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        if(this.tableOptions.sortBy[0] == 'Sda.sdadate') {
                            this.tableOptions['sortDesc'] = [true]
                        }
                    }
                    if(this.tableOptions.sortBy[0] == 'Sda.sdadate' && this.tableOptions.sortDesc.length == 0){
                        this.tableOptions['sortDesc'] = [true]
                    }

                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }

                    this.hideDefaultFooter = false
                    this.loading.sdas = true;
                    this.searchAllSdas(payload)
                        .then(() => {
                            this.loading.sdas = false;
                        })
                        .catch(()=>{
                            this.loading.sdas = false;
                        })
                        .finally(()=>{
                            this.loading.sdas = false;
                        })
                } else {
                    this.loadSdas()
                }
            },
			formatThisNumber(value,format){
				return numberFormat(value,format)
			},
            loadFilterItems(filter) {
                return new Promise((resolve, reject) => {
                    if(filter && filter.length > 0) {
                        this.loading.filterItems[filter] = true
                        api
                            .get('/sdas/filter-options/' + filter)
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else {
                        reject('Filter empty')
                    }
                })
            },
			loadSdas() {
                this.hideDefaultFooter = false
				if(!this.loading.sdas) this.loading.sdas = true;

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

				let payload = {
					tableOptions: this.tableOptions,
					// filterOptions: this.filterOptions
				}
				this.getAllSdas(payload)
					.then(()=>{
						this.loading.sdas = false;
					})
					.catch(()=>{
						this.loading.sdas = false;
					})
					.finally(()=>{
						this.loading.sdas = false;
					})
			},
            newShippingInstruction() {
                let tab = window.open('/v1/sdas/add', '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
			resetSearch (filter = null) {
				// this.hideDefaultFooter = false
				// this.loading.lcas = false
				// this.searchField = 'Sda.title'; //default search field
				// this.searchTerm = null;
				// this.tableOptions.itemsPerPage = 10
				// this.filterOptions = {};

                this.searching = false
                this.hideDefaultFooter = false
                this.filterValues.buyer = null
                this.filterValues.pod = null
                this.filterValues.supplier = null
                this.tableOptions.page = 1
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                switch(filter){
                    case 'eta':
                    case 'etd':
                    case 'ets':
                    case 'osd':
                        this.searchValues[filter] = []
                        break
                }

                this.tableOptions.sortBy = []
                this.tableOptions.sortDesc = []
                this.filterSdas()
			},
			searchSdas (field) {
                if(this.searchValues[field] == null || this.searchValues[field].length == 0){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
				} else {
                    //TODO search sdas
                    // this.filterOptions = {}
                    // this.hideDefaultFooter = true
                    // this.loading.sdas = true;
                    // this.loading.search = true;
                    // let payload = {
                    //     tableOptions: this.tableOptions,
                    //     search: {
                    //         field: this.searchField,
                    //         value: this.searchTerm
                    //     }
                    // }

                    this.searching = true
                    this.filterValues.buyer = null
                    this.filterValues.pod = null
                    this.filterValues.supplier = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) {
                            this.searchValues[key] = null
                        }
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'title':
                            fieldName = 'Sda.Contract.title'
                            sortFieldName = 'Sda.sdadate'
                            break
                    }

                    this.loading.sdas = true

                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: ['title'].includes(field) ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = ['title'].includes(field) ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }
                    this.searchAllSdas(payload)
                        .then(() => {
                            this.loading.search = false;
                            this.loading.sdas = false;
                        })
                        .catch(()=>{
                            this.loading.search = false;
                            this.loading.sdas = false;
                        })
                        .finally(()=>{
                            this.loading.search = false;
                            this.loading.sdas = false;
                        })
				}
			},
            updateDataTable(options) {
			    this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.title != null) field = 'title'
                    this.searchSdas(field)
                } else {
                    this.filterSdas()
                }
            },
			updateSda (val) {
                let tab = window.open('/v1/sdas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
                // router.push({name: 'update_lca', params: { lcaId : val}}).catch(err => {})
			},
			viewPdf (sdaId) {
			    let sda = this.Sdas.find( sda => sda.Sda.id == sdaId)
                let document = encodeURIComponent(sda?.Sda.title + '_SI')
				// let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
					process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
					+ process.env.VUE_APP_BASE_URL
					+ '/v1/sdas/print/'
					+ sdaId
					+ '/'
					+ uuidv4()
					+ '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
					, "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
			},
		},
		watch: {
			// filterOptions: {
			// 	handler(){
			// 		if(!this.loading.search) {
			// 			this.loadSdas()
			// 		}
			// 	},
			// 	deep: true
			// },
			// tableOptions: {
			// 	handler(){
			// 		this.loadSdas()
			// 	},
			// 	deep: true
			// },
		},
		created(){
			// let dt = new Date()
			// let defaultYear = dt.getFullYear() - 1
			// this.loadSdas()

            this.searchValues.title = null
            this.filterValues.buyer = null
            this.filterValues.pod = null
            this.filterValues.supplier = null

            this.loadSdas()

            if(this.filterItems.buyer.length == 0) this.loadFilterItems('buyer')
            if(this.filterItems.pod.length == 0) this.loadFilterItems('pod')
            if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
		}
	}
</script>

<style>
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>